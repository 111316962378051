import React from "react";

function Header() {
    return (
        <header class="bg-primary bg-gradient text-white relative">
            <div class="px-5 text-center">
                <h1 class="fw-bolder">Descubre los Hechos Esenciales del IPSA al instante</h1>
                <p class="lead">Descubre los Hechos Esenciales del IPSA al Instante. Accede de forma gratuita a la tabla
                    interactiva con la información financiera más relevante. Busca por entidad o tipo de hecho esencial
                    y mantente informado como nunca antes. <br />¡Accede a la información financiera clave de forma rápida y sencilla! </p>
                <a class="btn btn-lg btn-dark" href="https://mailchi.mp/f721b3454ef7/wwwinfobolsacl">Suscríbete</a>
            </div>
        </header>
    )
}

export default Header