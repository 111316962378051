import logo from './logo.svg';
import './App.css';
import DataTable from './component/DataTable';
import Navbar from './component/layout/navbar';
import FooterX from './component/layout/footer';
import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import Header from './component/header';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Header />
        <div className='relative'>
        <div className='mt-5 container'>
        <Routes>
          <Route path="/" element={<DataTable />} />
          <Route path="/resumen_accion" element={<DataTable />} />
          <Route path="/treemap_data" element={<DataTable />} />
        </Routes>
        </div>
        </div>
        <FooterX />
      </div>
    </Router>
  );
}

export default App
